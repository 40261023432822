// import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faSquareQuestion } from "@fortawesome/pro-light-svg-icons/faSquareQuestion";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
// import { faFacebookF, faInstagram, faPinterestP } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { vars } from "../styles/global.css";
import * as styles from "../styles/layout.css";
import { Link } from "gatsby";

import logo from "../images/logo_white.png";
import { faSearch } from "@fortawesome/pro-light-svg-icons";

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const [canHover, setCanHover] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(-1);

  const toggleNav = React.useCallback(() => {
    if (window.innerWidth >= vars.breakpoints.desktop) {
      return;
    }
    const layoutEl = document.querySelector("#layout");
    if (!isOpen) {
      setScrollPosition(window.scrollY);
      layoutEl.style.top = -window.scrollY + "px";
      layoutEl.style.position = "fixed";
    } else {
      layoutEl.style.position = "relative";
      layoutEl.style.top = 0;
      window.scrollTo({ left: 0, top: scrollPosition, behavior: "instant" });
    }

    setIsOpen(!isOpen);
  }, [isOpen, scrollPosition]);

  React.useEffect(() => {
    if (window.innerWidth < vars.breakpoints.desktop) {
      document.documentElement.classList.toggle("modal-open", isOpen);
    } else {
      document.documentElement.classList.remove("modal-open");
    }
  }, [isOpen]);

  const menuRef = React.useRef(null);
  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
      setOpenMenu(-1);
      // setCanHover(true)
    }
  };

  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      setOpenMenu(-1);
      // setCanHover(true)
    }
  };

  const closeMenus = () => {
    setIsOpen(false);
    setOpenMenu(-1);
    document.documentElement.classList.remove("modal-open");
    const layoutEl = document.querySelector("#layout");
    layoutEl.style.position = "relative";
      layoutEl.style.top = 0;
      window.scrollTo({ left: 0, top: scrollPosition, behavior: "instant" });
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.addEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const handleNavClick = (index, level) => {
    setOpenMenu(openMenu === index ? -1 : index);
    // //console.log(el);
    if (level === 2) {
      setIsOpen(false);
      // setCanHover(false)
    }

    // if clicks have been used to navigate, disable hover
    // setCanHover(openMenu === index ? true : false)

    // if(openMenu === index){
    //   setTimeout(() => {
    //     setCanHover(true);
    //   }, 1000);
    // }else{
    //   setCanHover(false);
    // }

    // const layoutEl = document.querySelector("#layout");
    // layoutEl.style.position = "relative";
    // layoutEl.style.top = 0;
    // setIsOpen(false);
  };

  const handleNavKey = (e, index, level) => {
    if (e.key === "Down" || e.key === "ArrowDown") {
      if (openMenu !== index) {
        handleNavClick(index, level);
      }
      e.preventDefault();
    } else if (e.key === "Up" || e.key === "ArrowUp") {
      if (openMenu === index) {
        handleNavClick(index, level);
      }
      e.preventDefault();
    }
  };

  return (
    <>
      <div className={`${styles.header} ${isOpen ? styles.headerOpen : ""}`}>
        <div
          className={`${styles.overlay} ${isOpen ? styles.overlayOpen : ""}`}
          onClick={toggleNav}
          role="dialog"
          aria-label="Main Menu"
        ></div>

        <div className={styles.headerInner}>
          {!isOpen && (
            <>
              <div className={styles.hamBox}>
                <button
                  className={styles.hamButton}
                  aria-label="Open navigation menu"
                  aria-haspopup="true"
                  aria-expanded={isOpen}
                  type="button"
                  onClick={toggleNav}
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    style={{
                      fontSize: "1.4rem",
                      width: "25px",
                      height: "25px",
                    }}
                  />
                </button>
              </div>
              <div className={styles.headerLogo2}>
                {/* Maldives Guide */}
                <Link to="/">
                  <img
                    src={logo}
                    alt="Maldives Guide Logo"
                    height="48"
                    width="160"
                  />
                </Link>
              </div>
            </>
          )}
          <nav
            className={`${styles.navMenuContainer} ${
              isOpen ? styles.navMenuOpen : ""
            }`}
            role="navigation"
            ref={menuRef}
          >
            <div className={styles.close}>
              <button
                type={"button"}
                className={styles.closeButton}
                aria-label="Close navigation menu"
                aria-haspopup="true"
                aria-expanded={isOpen}
                onClick={toggleNav}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ fontSize: "1.4rem" }}
                />
              </button>
            </div>

            <div className={styles.headerLogo}>
              <Link to="/" onClick={() => {closeMenus()}} role="button" tabIndex={1}>
                <img
                  src={logo}
                  alt="Maldives Guide Logo"
                  height="48"
                  width="160"
                />
              </Link>
            </div>
            <ul className={styles.headerMenu}>
              <li className={styles.navItem}>
                <button
                  className={`${styles.navButton} ${
                    openMenu === 0 ? styles.navButtonActive : ""
                  }`}
                  type="button"
                  onClick={() => handleNavClick(0, 1)}
                  onKeyDown={(e) => handleNavKey(e, 0, 1)}
                  aria-expanded={openMenu === 0}
                  tabIndex={2}
                >
                  <span>Resorts</span>
                  <span className={`${styles.chevron}`}>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </span>
                </button>
                <div
                  className={`${styles.navSubmenu} ${
                    canHover ? styles.navSubmenuHover : ""
                  } ${openMenu === 0 ? styles.navSubmenuOpen : ""}`}
                >
                  <div>
                    <div className={`${styles.navSubTitle}`}>
                      <div>Resorts</div>
                      <button
                        className={`${styles.navSubClose}`}
                        type="button"
                        onClick={() => handleNavClick(0, 1)}
                        onKeyDown={(e) => handleNavKey(e, 0, 1)}
                        aria-expanded={openMenu === 0}
                      >
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${styles.navSubInner} ${styles.navInnerThree}`}
                  >
                    <div>
                      <div className={styles.navSubHeader}>Resort Types</div>
                      <ul className={styles.navUl}>
                        <li>Best Resorts</li>
                        <li>All Inclusive Resorts</li>
                        <li>Luxury Resorts</li>
                        <li>Affordable Resorts</li>
                        <li>Family Friendly Resorts</li>
                        <li>Adult Only Resorts</li>
                        <li><Link to="/resorts" onClick={() => {closeMenus()}}>All Resorts</Link></li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>Features</div>
                      <ul className={styles.navUl}>
                        <li>Resorts by Seaplane</li>
                        <li>Resorts by Speedboat</li>
                        <li>Resorts by Domestic Flight</li>
                      </ul>
                    </div>
                    <div className={styles.navSubFeature}>FEATURE</div>
                  </div>
                </div>
              </li>
              <li className={styles.navItem}>
                <button
                  className={`${styles.navButton} ${
                    openMenu === 1 ? styles.navButtonActive : ""
                  }`}
                  type="button"
                  onClick={() => handleNavClick(1, 1)}
                  onKeyDown={(e) => handleNavKey(e, 1, 1)}
                  aria-expanded={openMenu === 1}
                  tabIndex={3}
                >
                  <span>Regions</span>
                  <span className={`${styles.chevron}`}>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </span>
                </button>
                <div
                  className={`${styles.navSubmenu} ${
                    canHover ? styles.navSubmenuHover : ""
                  } ${openMenu === 1 ? styles.navSubmenuOpen : ""}`}
                >
                  <div>
                    <div className={`${styles.navSubTitle}`}>
                      <div>Regions</div>
                      <button
                        className={`${styles.navSubClose}`}
                        type="button"
                        onClick={() => handleNavClick(1, 1)}
                        onKeyDown={(e) => handleNavKey(e, 1, 1)}
                        aria-expanded={openMenu === 1}
                      >
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${styles.navSubInner} ${styles.navInnerFour}`}
                  >
                    <div>
                      <div className={styles.navSubHeader}>Central</div>
                      <ul className={styles.navUl}>
                        <li>Malé Atoll (Kaafu)</li>
                        <li>North Ari Atoll</li>
                        <li>South Ari Atoll</li>
                        <li>Baa Atoll</li>
                        <li>Vaavu Atoll</li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>Inner</div>
                      <ul className={styles.navUl}>
                        <li>Lhaviyani Atoll</li>
                        <li>Raa Atoll</li>
                        <li>Faafu Atoll</li>
                        <li>Dhaalu Atoll</li>
                        <li>Meemu Atoll</li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>North</div>
                      <ul className={styles.navUl}>
                        <li>Haa Alifu Atoll</li>
                        <li>Haa Dhaalu Atoll</li>
                        <li>Shaviyani Atoll</li>
                        <li>Noonu Atolll</li>
                        <li>Raa Atoll</li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>South</div>
                      <ul className={styles.navUl}>
                        <li>Addu Atoll</li>
                        <li>Gaafu Atoll (Huvadhu)</li>
                        <li>Thaa Atoll</li>
                        <li>Laamu Atoll</li>
                        <li>Gnaviyani Atoll</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className={styles.navItem}>
                <button
                  className={`${styles.navButton} ${
                    openMenu === 2 ? styles.navButtonActive : ""
                  }`}
                  type="button"
                  onClick={() => handleNavClick(2, 1)}
                  onKeyDown={(e) => handleNavKey(e, 2, 1)}
                  aria-expanded={openMenu === 2}
                  tabIndex={4}
                >
                  <span>Things to Do</span>
                  <span
                    className={`${styles.chevron}`}
                  >
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </span>
                </button>
                <div
                  className={`${styles.navSubmenu} ${
                    canHover ? styles.navSubmenuHover : ""
                  } ${openMenu === 2 ? styles.navSubmenuOpen : ""}`}
                >
                                    <div>
                    <div className={`${styles.navSubTitle}`}>
                      <div>Things to Do</div>
                      <button
                        className={`${styles.navSubClose}`}
                        type="button"
                        onClick={() => handleNavClick(2, 1)}
                        onKeyDown={(e) => handleNavKey(e, 2, 1)}
                        aria-expanded={openMenu === 2}
                        >
                        <FontAwesomeIcon
                          icon={faTimes}
                          ></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${styles.navSubInner} ${styles.navInnerFour}`}
                  >
                    <div>
                      <div className={styles.navSubHeader}>Active</div>
                      <ul className={styles.navUl}>
                        <li>Snorkelling</li>
                        <li>Scuba Diving</li>
                        <li>Surfing</li>
                        <li>Fishing</li>
                        <li>Watersports</li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>Relax</div>
                      <ul className={styles.navUl}>
                        <li>Best Beaches</li>
                        <li>Best Spas</li>
                        <li>Kids Clubs</li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>Experience</div>
                      <ul className={styles.navUl}>
                        <li>Dining</li>
                        <li>Local Culture</li>
                        <li>Excursions</li>
                        <li>Wildlife</li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>Celebrate</div>
                      <ul className={styles.navUl}>
                        <li>Weddings</li>
                        <li>Honeymoons</li>
                        <li>Birthdays</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className={styles.navItem}>
                <button
                  className={`${styles.navButton} ${
                    openMenu === 4 ? styles.navButtonActive : ""
                  }`}
                  type="button"
                  onClick={() => handleNavClick(4, 1)}
                  onKeyDown={(e) => handleNavKey(e, 4, 1)}
                  aria-expanded={openMenu === 1}
                  tabIndex={6}
                >
                  <span>Price Guide</span>
                  <span
                    className={`${styles.chevron}`}
                  >
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </span>
                </button>
                <div
                  className={`${styles.navSubmenu} ${
                    canHover ? styles.navSubmenuHover : ""
                  } ${openMenu === 4 ? styles.navSubmenuOpen : ""}`}
                >
                                    <div>
                    <div className={`${styles.navSubTitle}`}>
                      <div>Price Guide</div>
                      <button
                        className={`${styles.navSubClose}`}
                        type="button"
                        onClick={() => handleNavClick(4, 1)}
                        onKeyDown={(e) => handleNavKey(e, 4, 1)}
                        aria-expanded={openMenu === 4}
                        >
                        <FontAwesomeIcon
                          icon={faTimes}
                          ></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${styles.navSubInner} ${styles.navInnerThree}`}
                  >
                    <div>
                      <div className={styles.navSubHeader}>Essential Costs</div>
                      <ul className={styles.navUl}>
                        <li>Accommodation</li>
                        <li>Transport</li>
                        <li>Food &amp; Drink</li>
                        <li>Internet &amp; Phone</li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>Optional Costs</div>
                      <ul className={styles.navUl}>
                        <li>Scuba Diving</li>
                        <li>Wine</li>
                        {/* <li>The Dom Perignon Index</li> */}
                      </ul>
                    </div>
                    <div className={styles.navSubFeature}>FEATURE</div>
                  </div>
                </div>
              </li>
              <li className={styles.navItem}>
                <button
                  className={`${styles.navButton} ${
                    openMenu === 5 ? styles.navButtonActive : ""
                  }`}
                  type="button"
                  onClick={() => handleNavClick(5, 1)}
                  onKeyDown={(e) => handleNavKey(e, 5, 1)}
                  aria-expanded={openMenu === 5}
                  tabIndex={7}
                >
                  <span>Travel Guide</span>
                  <span
                    className={`${styles.chevron}`}
                  >
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </span>
                </button>
                <div
                  className={`${styles.navSubmenu} ${
                    canHover ? styles.navSubmenuHover : ""
                  } ${openMenu === 5 ? styles.navSubmenuOpen : ""}`}
                >
                                    <div>
                    <div className={`${styles.navSubTitle}`}>
                      <div>Travel Guide</div>
                      <button
                        className={`${styles.navSubClose}`}
                        type="button"
                        onClick={() => handleNavClick(5, 1)}
                        onKeyDown={(e) => handleNavKey(e, 5, 1)}
                        aria-expanded={openMenu === 5}
                        >
                        <FontAwesomeIcon
                          icon={faTimes}
                          ></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${styles.navSubInner} ${styles.navInnerThree}`}
                  >
                    <div>
                      <div className={styles.navSubHeader}>Travel Guide</div>
                      <ul className={styles.navUl}>
                        <li><Link to="/travel-guide" onClick={() => {closeMenus()}}>Maldives Travel Guide</Link></li>
                        <li>Getting to the Maldives</li>
                        <li>Climate &amp; Weather</li>
                        <li>Local Transport</li>
                        <li>Currency</li>
                        <li>Power &amp; Connectivity</li>
                      </ul>
                    </div>
                    <div>
                      <div className={styles.navSubHeader}>Good to Know</div>
                      <ul className={styles.navUl}>
                        <li>Tourist Visa</li>
                        <li>Culture &amp; Etiquette</li>
                        <li>Safety</li>
                        <li>Solo Travel</li>
                      </ul>
                    </div>
                    <div className={styles.navSubFeature}>FEATURE</div>
                  </div>
                </div>
              </li>
            </ul>
            <div className={styles.navSearch}>
                {/* <input type="text" placeholder="Search Maldives Guide" className={styles.navSearchBox}/>
                <button type="button" className={styles.navSearchButton}><FontAwesomeIcon icon={faSearch} /></button> */}
              </div>
            {/* <div className={styles.headerSocial}>
                <FontAwesomeIcon icon={faPinterestP} />
                <FontAwesomeIcon icon={faInstagram} />
                <FontAwesomeIcon icon={faFacebookF} />
            </div> */}
            {/* <div className={styles.headerSignup}>
              <div>Sign Up</div>
            </div> */}

            {/* <div
              className={styles.navMenuFlair}
              onClick={toggleNav}
              role="dialog"
              aria-label="Close Menu"
            ></div> */}
          </nav>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {};

export default Header;
